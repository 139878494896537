import {createAction} from "@reduxjs/toolkit";
import {ADD_NEW_ADDRESS, SAVE_CART, SET_DELIVERY_ADDRESS, SET_SELECTED_DELIVERY_ADDRESS} from "./types";


// Don't worry about the earlier implementation, I got smarter
export const contractorAction = (type: string) => createAction(type, (payload: any) => {
  return {
    payload
  }
})

export const setDeliveryAddress = (payload: any)  => {
  return contractorAction(SET_DELIVERY_ADDRESS)(payload)
}

export const setSelectedDeliveryAddress = (id: number) =>{
  return contractorAction(SET_SELECTED_DELIVERY_ADDRESS)(id)
}


export const addNewAddress = (address:any) =>{
  return contractorAction(ADD_NEW_ADDRESS)(address)
}

export const saveCart = (cart:any) =>{
  return contractorAction(SAVE_CART)(cart)
}
