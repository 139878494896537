import {configureStore} from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import {persistReducer, persistStore} from 'redux-persist'
import {rootReducer} from "./reducers";
const persistConfig = {
  key:'app',
  storage: storageSession
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware:[thunk]
});

export const persistor = persistStore(store)
