import React, {useEffect, useRef} from "react";

import AppRouter from "./app/index";
import {configureAbly} from "@ably-labs/react-hooks";
import moment from 'moment-timezone';
import * as Sentry from "@sentry/react";
import {PersistGate} from "reduxjs-toolkit-persist/es/integration/react";
import {Provider} from "react-redux";
import { BrowserTracing } from "@sentry/tracing";
import {Replay} from "@sentry/browser";
import isOnline from 'is-online';
import toast from "react-hot-toast";
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {Theme} from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';
import { ReactQueryDevtools } from 'react-query/devtools'


import {persistor, store} from "redux/store";
import './App.css';
import "react-tooltip/dist/react-tooltip.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "react-awesome-lightbox/build/style.css";



import ErrorBoundary from "./layouts/ErrorBoundary";

import {InternetIcon} from "components/common/Icons";
import {COLORS} from "utils/constants";



Sentry.init({
  dsn: "https://bb5b1b531fcc4351aa8af013566bf4f7@o4504025736347648.ingest.sentry.io/4504025739821058",
  integrations: [
    new BrowserTracing(),
    new Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

function App() {

  const appRef = useRef<HTMLDivElement>(null)

  useEffect(()=> {
    if(appRef.current){
      configureAbly({ key: "5JQyAw.PzmrlQ:srrkw50XYKn0qlCo0tYItvh3rJHEdNxh5V5TrWq8_qY"});
      moment.tz.setDefault(String(Intl.DateTimeFormat().resolvedOptions().timeZone));

      const loader = document?.getElementById("appLoad");
      if(loader){
        loader.style.display="none"
      }

      (async ()=>{
        const online = await isOnline();
        if(!online){
          toast.custom("No Internet", {
            style:{
              backgroundColor: COLORS.colorBlack,
              color: COLORS.primaryYellow
            },
            icon:<InternetIcon color={COLORS.primaryYellow} size={14}/>
          })
        }
      })()
    }
  }, [appRef.current]);


  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div ref={appRef}>
      <AnimatePresence mode={"wait"}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <BrowserRouter>
                  <Theme>
                    <AppRouter/>
                  </Theme>
                </BrowserRouter>
              </PersistGate>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ErrorBoundary>
      </AnimatePresence>
    </div>
  );
}

export default App;
