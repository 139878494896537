// @ts-ignore
import StompJS from 'stompjs/lib/stomp.js';
import {socketHost} from "utils/helpers";


const Stomp = StompJS.Stomp;

const useWebsocket = (
  topic: string,
  onMessage?: (message:any) => void
) => {
  try{
    const client = Stomp.over(new WebSocket(socketHost));
    client.connect({}, () => {
      client.subscribe(topic, (message: any) => {
        const data = JSON.parse(message?.body ?? "{}");
        onMessage?.(data?.data ?? {})
      }, {})
    });
  }catch (e) {
    console.log(e)
  }
}

export default useWebsocket
