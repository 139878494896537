import {instance} from "../rest_service";

export const attemptLogin = async (payload: any) => {
  return await instance.post("/v1/auth/login", payload, {})
}

export const logOut = async (sessionId: number) => {
  return await instance.get(`/v1/auth/logout/${sessionId}`)
}


export const resetPasswordLink = async (email:string, userType:string) => {
  return await instance.get("/v1/auth/attempt-password-reset", {
    params:{
      email,
      userType
    }
  })
}

export const resendUserVerificationEmail = async (userId: number) => {
  return await instance.get(`/v1/auth/resend-email/${userId}`)
}

export const resetUserPassword = async (data:any) => {
  return await instance.post("/v1/auth/reset-password", data)
}


export const setNewPassword = async (data:any) => {
  return await instance.post("/v1/auth/new-password", data)
}

export const checkUser = async (email: string, userType: string) => await instance.get("/v1/auth/login/check-user", {
  params:{
    email,
    userType
  }
})



