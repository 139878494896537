import {
  NEW_NOTIFICATION_MESSAGE,
  SET_PERSONAL_INFO,
  SET_USER_ID,
  USER_NOTIFICATIONS,
  ZOOM_IMAGE
} from "../action/types";
import {createReducer} from "@reduxjs/toolkit";

const INITIAL_STATE = {
  notifications:[],
  zoomImage: ""
}


const userReducer = createReducer(INITIAL_STATE, (builder)=> {
  builder
      .addCase(SET_USER_ID, (state: any, action: any) => {
        state.userId = action.payload
      })
      .addCase(SET_PERSONAL_INFO, (state:any, action:any) => {
        state.userInfo = action.payload
      })
    .addCase(USER_NOTIFICATIONS, (state: any, action:any) => {
      return {
        ...state,
        notifications:action.payload
      }
    })
    .addCase(NEW_NOTIFICATION_MESSAGE, (state:any, action:any )=> {
      const notifications = [...(state.notifications || [])]
      notifications.push(action.payload)
      return {
        ...state,
        notifications:notifications
      }
    })
    .addCase(ZOOM_IMAGE,  (state, action:any)=> {
      return {
        ...state,
        zoomImage: action.payload
      }
    })


      .addDefaultCase((state) => {
        return state
      })
})

export default userReducer
