import React from "react";
import {Toaster} from "react-hot-toast";
import styled from 'styled-components'

import {COLORS, MEDIA} from "utils/constants";
import logo from '../assets/images/svg/logo.svg'


type HeaderProp = {
  showMobile?: boolean,
  hasRight?: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100vw;
  position: relative;
  overflow-y: hidden;
  height: 100dvh;
  bottom: 0;
  .spacer{
    display: none;
  }
  ${MEDIA.isMobile}{
    height: 100dvh;
    .spacer{
      display: block;
    }
  }
`

const Header = styled.div<HeaderProp>`
  height: 12vh;
  min-height: 60px;
  max-height: 10vh;
  background-color: ${COLORS.primaryYellow};
  padding: 0 78px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  ${MEDIA.isMobile}{
    display: ${({showMobile}:HeaderProp) => showMobile ? 'flex': 'none'};
    height: 54px;
    align-items: center;
    justify-content: ${({hasRight}:HeaderProp) => hasRight ? 'space-between': 'center'};
    padding: 0 1rem;
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }
`

const PageWrapper = styled.div<HeaderProp>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: max(10vh, 60px);
  ${MEDIA.isMobile}{
    margin-top: 0;
    height: 100%;
    overflow-y: auto;
  }
`

const Logo = styled.img`
  height: 20px;
  ${MEDIA.isMobile}{
    height: 16px;
  }
`



const AppWrapper = (props: any) => {
  const { showMobile, hidePad, renderRight} = props;

  return (
    <Wrapper {...props.style}>
      <Header showMobile={showMobile} hasRight={renderRight}>
        <Logo alt={'logo'} src={logo}/>
        {renderRight}
      </Header>
      {
        showMobile  && !hidePad &&  <div className={'spacer'} style={{height:'57px', width:'100vw', background:COLORS.primaryYellow}}></div>
      }

      <PageWrapper showMobile={showMobile}>
        {
          props.children
        }
      </PageWrapper>
      <Toaster
        position={"top-center"}
        reverseOrder={false}
        gutter={8}
        containerStyle={{
          zIndex: 99999999,
          borderRadius: 0
        }}
        toastOptions={{
          // Define default options
          duration: 7000,
          style: {
            background: COLORS.primaryBlack,
            color: COLORS.primaryYellow,
            fontWeight:'bold',
            borderRadius: 0
          },

          // Default options for specific types
          success: {
            duration: 7000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
          error: {
            duration: 7000,
            theme: {
              props: 'red',
              secondary: 'black'
            }
          }
        }
        }
      />
    </Wrapper>
  )
}


export default AppWrapper
