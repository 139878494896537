import {createReducer} from "@reduxjs/toolkit";
import {
  PAYMENT_DETAILS,
  SELECTED_ADMIN,
  SET_NEW_PRODUCT_DATA,
  SET_SELECTED_DRIVER,
  SET_SELECTED_PRODUCT,
  SET_STORE,
  SET_STORE_DRIVERS,
  SET_STORE_PRODUCTS,
  SET_STORE_RATING,
  SET_STORE_SETUP_DATA,
  SET_SUPPLIER_ORDERS,
  SET_SUPPLIER_RENTALS,
  SET_SUPPLIER_REQUESTS,
  SET_SUPPLIER_SELECTED_ORDER, SUPPLIER_DELIVERY_THIS_WEEK, SUPPLIER_RENTAL_READY_PICKUP,
  UPDATE_ORDER_COMPLETED
} from "../action/types";
import {OrderState} from "utils/constants";

const INITIAL_STATE = {
  storeOrders:{
    [OrderState.others]:{},
    [OrderState.completed]:{},
    [OrderState.rejected]:{},
    [OrderState.canceled]:{},
  },
  requests: [],
  deliverySoon: [],
  rentalReady: []
}


const supplierReducer = createReducer(INITIAL_STATE, (builder)=> {
  builder.addCase(SET_STORE_SETUP_DATA, (state: any, action:any) => {
    state.storeSetUpData = action.payload
  })
    .addCase(SET_STORE, (state:any, action:any)=>{
      state.store = action.payload
    })
    .addCase(SET_NEW_PRODUCT_DATA, (state:any, action:any)=> {
      return {
        ...state,
        newProduct:action.payload
      }
    })
    .addCase(SET_STORE_PRODUCTS, (state:any, action:any)=>{
      return {
        ...state,
        storeProducts:action.payload
      }
    })
    .addCase(SET_SELECTED_PRODUCT, (state:any, action:any)=>{
      return {
        ...state,
        selectedProduct: action.payload
      }
    })
    .addCase(PAYMENT_DETAILS, (state:any, {payload}:any)=>{
      return {
        ...state,
        paymentDetails: payload
      }
    })
    .addCase(SET_SUPPLIER_ORDERS, (state:any, action:any)=> {
      const {storeOrders} = state;
      const {data, isRefresh, status} = action.payload
      const temp = JSON.parse(JSON.stringify(storeOrders || []))
      let list: any;
      if (isRefresh) {
        list = {}
      } else {
        list = temp[status] || {};
      }
      data?.forEach((order: any) => {
        Object.assign(list, {[order.id]: order})
      });
      return {
        ...state,
        storeOrders: {
          ...temp,
          [status]: list
        }
      }
    })
    .addCase(UPDATE_ORDER_COMPLETED, (state, action:any)=> {
      return {
        ...state,
        storeOrders: action.payload
      }
    })
    .addCase(SET_STORE_RATING, (state:any, action:any)=>{
      return {
        ...state,
        storeRating: action.payload
      }
    })
    .addCase(SET_SUPPLIER_SELECTED_ORDER, (state:any, action:any)=>{
      return {
        ...state,
        selectedOrder: action.payload
      }
    })
    .addCase(SET_SELECTED_DRIVER, (state:any, action:any)=> {
      return {
        ...state,
        selectedDriver: action.payload
      }
    })
    .addCase(SET_STORE_DRIVERS, (state:any, action:any)=> {
      return {
        ...state,
        storeDrivers: action.payload
      }
    })
    .addCase(SET_SUPPLIER_RENTALS, (state:any, action:any)=>{
      return {
        ...state,
        rentals: action.payload
      }
    })
    .addCase(SELECTED_ADMIN, (state, action:any) =>{
      return{
        ...state,
        selectedAdmin:action.payload
      }
    })
    .addCase(SET_SUPPLIER_REQUESTS, (state, action:any)=> {
      return {
        ...state,
        requests: action.payload
      }
    })
    .addCase(SUPPLIER_DELIVERY_THIS_WEEK, (state, action:any)=> {
      return {
        ...state,
        deliverySoon: action.payload
      }
    })
    .addCase(SUPPLIER_RENTAL_READY_PICKUP, (state, action:any)=> {
      return {
        ...state,
        rentalReady: action.payload
      }
    })
    .addDefaultCase((state) => {
      return state
    })
})


export default supplierReducer
