import React from 'react'
import * as Sentry from "@sentry/react";
import Column from "layouts/Column";
import Loader from "components/common/Loader";


class ErrorBoundary extends React.Component<any, any> {
  constructor(props:any) {
    super(props);
    this.state = {hasError: false, reloaded: false};
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error:any) {
    const userType = window.localStorage.getItem("buildhub:userType");
    const errorString = String(error)
    try{
      if (errorString?.includes("e._result.default") || errorString?.includes("Loading chunk") || errorString?.includes("Loading CSS chunk")) {
        if (this.state.reloaded) {
          window.location.replace(`${window.location.origin}/${userType?.toLowerCase()}/dashboard`)
        } else {
          window.location.reload();
          this.setState({reloaded: true})
        }
      }else {
        if(!String(error).includes("sw.js")){
          if(window.location.origin.includes("https://app.buildhubapp.com")){
            Sentry.captureException(error);
          }
        }else {
          window.location.replace(`${window.location.origin}/auth/login/as`)
        }
      }
    }catch (e) {
      window.location.replace(window.location.origin)
    }
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if(this.state.hasError){
      return (
        <Column style={{flex:1, height:'100dvh', alignItems:'center', justifyContent:'center'}}>
          <Loader size={"small"}/>
        </Column>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary
