import {firebaseRemoteConfig} from "services/firebase_service";
import {getAll, getValue} from "firebase/remote-config";
import defaultConfig from "../data/remote_config.json"

// @ts-ignore
firebaseRemoteConfig().defaultConfig = defaultConfig;


firebaseRemoteConfig().settings.minimumFetchIntervalMillis = 0;




export const getFirebaseConfig = async () => {
  return getAll(firebaseRemoteConfig());
}
export const getBinContent = ()  => {
  const binContent = getValue(firebaseRemoteConfig(), "bin_content").asString();
  try{
    return JSON.parse(binContent)
  }catch (e){
    return binContent?.split(",")
  }
}

export const getCountries = () => {
  const supportedCountries = getValue(firebaseRemoteConfig(), "country_config").asString();
  try{
    return JSON.parse(supportedCountries)
  }catch (e){
    return []
  }
}

export const getAgeBracket = () => {
  const ageBracket = getValue(firebaseRemoteConfig(), "age_bracket").asString();
  try{
    return JSON.parse(ageBracket)
  }catch (e){
    return []
  }
}


export const isSSOActive = () => {
  const sscActive = getValue(firebaseRemoteConfig(), "sso_active").asBoolean();
  console.log(sscActive)
  return sscActive;
}

