import { Column} from "./index";
import Loader from "../components/common/Loader";

const PageLoading = () => {
  return (
    <Column style={{
      flex:1,
      alignItems:'center',
      justifyContent:'center',
      minHeight:'100dvh',
      height:'100%'
    }}>
      <Loader size={"small"}/>
    </Column>
  )
}

export default PageLoading
