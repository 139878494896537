import {createReducer} from "@reduxjs/toolkit";
import {
  ADD_NEW_ADDRESS,
  CONTRACTOR_DELIVERY_TODAY,
  CONTRACTOR_RECENT_ACTIVITIES,
  CONTRACTOR_RECENT_PROJECTS, CONTRACTOR_RENTAL_EXPIRY,
  CONTRACTOR_STATS,
  CONTRACTOR_SUBACCOUNTS,
  NEW_ORDER_MODAL,
  ORDER_REQUEST_DATA,
  SAVE_CART,
  SELECTED_ADMIN,
  SET_ADDRESS_STORES,
  SET_BUSINESS_INFO,
  SET_CONTRACTOR_ORDER_REQUEST,
  SET_CONTRACTOR_ORDERS,
  SET_CONTRACTOR_PAYMENT_METHODS,
  SET_CONTRACTOR_RENTALS,
  SET_DELIVERY_ADDRESS,
  SET_FAVORITE_STORES,
  SET_LOCATION_STORES,
  SET_SELECTED_ADDRESS,
  SET_SELECTED_DELIVERY_ADDRESS,
  SET_SELECTED_ORDER
} from "../action/types";
import {OrderState} from "utils/constants";

const INITIAL_STATE = {
  deliveryAddress:[],
  selectedAddress: {},
  orderRequest: {},
  newOrderModal: false,
  orderRequests: {},
  storesInLocation: [],
  paymentMethods:[],
  orders:{
    [OrderState.others]:{},
    [OrderState.completed]:{},
    [OrderState.rejected]:{},
    [OrderState.canceled]:{},
  },
  recentProjects: [],
  recentActivities: [],
  stats: {},
  deliveryToday:[],
  rentalExpiringSoon:[]
}


const contractorReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(SET_DELIVERY_ADDRESS, (state:any, action:any)=>{
      return {
        ...state,
        deliveryAddress :action.payload
      }
    })
    .addCase(SET_SELECTED_DELIVERY_ADDRESS, (state:any, action:any)=>{
      const id = action.payload
      const allAddresses = state.deliveryAddress;
      return {
        ...state,
        selectedAddress : allAddresses.find((address: any) => address.id === id) || {}
      }
    })
    .addCase(ADD_NEW_ADDRESS, (state:any, action:any)=>{
       const existingAddresses = state.deliveryAddress;
       return {
         ...state,
         deliveryAddress: [...existingAddresses, action.payload]
       }
    })
    .addCase(SET_SELECTED_ADDRESS, (state:any, action:any)=>{
      return{
        ...state,
        selectedAddress: action.payload
      }
    })
    .addCase(SAVE_CART, (state:any, action:any)=>{
      return {
        ...state,
        cart: action.payload
      }
    })
    .addCase(SET_CONTRACTOR_ORDERS,  (state:any, action: any) => {
      const {orders} = state;
      const {data, status, isRefresh} = action.payload
      const temp = JSON.parse(JSON.stringify(orders)) // weird way of recreating this object
      let list: any;
      if (isRefresh) {
        list = {}
      } else {
        list = temp[status] || {};
      }
      data.forEach((order: any) => {
        Object.assign(list, {[order.id]: order})
      });
      return {
        ...state,
        orders: {
          ...temp,
          [status]: list
        }
      }
    })
    .addCase(SET_ADDRESS_STORES, (state:any, action:any) => {
      return {
        ...state,
        addressStores: action.payload
      }
    })
    .addCase(SET_SELECTED_ORDER, (state, action:any)=>{
      return {
        ...state,
        selectedOrder: action.payload
      }
    })
    .addCase(SET_BUSINESS_INFO, (state:any, action:any)=> {
      return {
        ...state,
        businessInfo: action.payload
      }
    })
    .addCase(SET_CONTRACTOR_RENTALS, (state:any, action:any)=>{
      return {
        ...state,
        rentals: action.payload
      }
    })
    .addCase(CONTRACTOR_SUBACCOUNTS, (state:any, action:any)=> {
      return {
        ...state,
        subAccounts: action.payload
      }
    })
    .addCase(SELECTED_ADMIN, (state:any, action:any)=> {
      return {
        ...state,
        selectedAdmin: action.payload
      }
    })
    .addCase(ORDER_REQUEST_DATA, (state:any, action:any)=>{
      return {
        ...state,
        orderRequest: action.payload
      }
    })
    .addCase(NEW_ORDER_MODAL, (state:any, action:any)=> {
      return {
        ...state,
        newOrderModal: action.payload
      }
    }).addCase(SET_CONTRACTOR_ORDER_REQUEST, (state:any, action:any)=> {
      return {
        ...state,
        orderRequests: action.payload
      }
    })
    .addCase(SET_LOCATION_STORES, (state:any, action:any)=> {
      return {
        ...state,
        storesInLocation: action.payload
      }
    })
    .addCase(SET_FAVORITE_STORES, (state, action:any)=> {
      return {
        ...state,
        favouriteStores:action.payload
      }
    })
    .addCase(SET_CONTRACTOR_PAYMENT_METHODS, (state, action:any)=>{
      return {
        ...state,
        paymentMethods: action.payload
      }
    })
    .addCase(CONTRACTOR_RECENT_PROJECTS, (state:any, action:any)=> {
      return {
        ...state,
        recentProjects: action.payload
      }
    })
    .addCase(CONTRACTOR_RECENT_ACTIVITIES, (state:any, action:any)=> {
      return {
        ...state,
        recentActivities: action.payload
      }
    })
    .addCase(CONTRACTOR_STATS, (state:any, action:any)=> {
      return {
        ...state,
        stats: action.payload
      }
    })
    .addCase(CONTRACTOR_DELIVERY_TODAY, (state:any, action:any) => {
      return {
        ...state,
        deliveryToday: action.payload
      }
    })

    .addCase(CONTRACTOR_RENTAL_EXPIRY, (state:any, action:any) => {
      return {
        ...state,
        rentalExpiringSoon: action.payload
      }
    })

    .addDefaultCase((state) => {
      return state
    })
})

export default contractorReducer
