import {CATEGORY} from "../@types/Types";
import {injectCSS} from "utils/helpers";
//import {isSSOActive} from "utils/remoteParams";
import binImg from "../assets/images/product_category/binPng.png"
import aggImg from "../assets/images/product_category/defaultAggregates.png"
import readyMixImg from "../assets/images/product_category/readyMix.png"

//import conImg from "../assets/images/product_category/defaultConcrete.png";
//import skidSteerImg from "../assets/images/product_category/skidsteer.png"

import binPng from "../assets/images/product_category/binPng.png"
import aggregatePng from "../assets/images/product_category/aggregatesPng.png"
import readyMixPng from "../assets/images/product_category/readyMixPng.png"
//import concretePng from "assets/images/product_category/concretePng.png"
//import equipmentsPng from "../assets/images/product_category/skidsteer.png"




export const SSO_ACTIVE = true;

export const AUTH_HOST = "https://buildhub-auth.vercel.app"


const isDarkMode = false
export const COLORS = {
  primaryYellow: !isDarkMode ? "#F9E600" : "rgb(37,37,37)",
  colorAppBar:!isDarkMode ? "#F9E600" : "#272728",
  colorAppBarText:!isDarkMode ? "#272728" : "#F9E600",
  iconYellow: !isDarkMode ? "#ceb900" : "",
  primaryBlack: !isDarkMode ?  "#272728" : "#F9E600",
  primaryGray: !isDarkMode ? "#F3F3F3" : "",
  colorBlack: !isDarkMode ? "#000000": "#F9E600",
  colorBg: !isDarkMode ? "#F5F5F5":"#121212",
  colorWhite: !isDarkMode ? "#ffffff": "",
  colorBorder: !isDarkMode ? "#d7dde4" : "rgba(220,220,220,0.5)",
  colorTabInactive: !isDarkMode ? "#767676": "",
  colorOrder: !isDarkMode ? "#DDDDDD" : "",
  colorAddressInfo: !isDarkMode ? "#575758": "",
  colorGray500: !isDarkMode ? "#767676": "",
  colorGray900: !isDarkMode ? "#575758": "",
  colorRed: !isDarkMode ? "#ff0000": "#ff0000",
  colorLine: !isDarkMode ? "#B7B7B8": "",
  colorTeal: !isDarkMode ? "#008080": "#008080",
  colorOrangeRed: !isDarkMode ? "#ff4500": "#ff4500",
  colorTitle: !isDarkMode ? "#272728" : "#ffffff",
  colorSubTitle: !isDarkMode ? "#767676" : "#eaeaea"
}

isDarkMode && injectCSS(`
  *{
    --color-bg: rgb(35, 35, 35);
  }
`)

export const MAPS_KEY="AIzaSyDaa018eeXYusB0FzodNZ23TM7u7djK0KY"
export const MEDIA = {
  isMobile: "@media (max-width: 1024px)"
}

export const userTypes:any = {
  SUPPLIER: "Supplier",
  CONTRACTOR: "Contractor",
  DRIVER: "Driver",
  HOMEOWNER: "Homeowner"
}





export const productCategoryTypes: any = {
  AGGREGATE: "Aggregate",
  READY_MIX: "Ready Mix",
  // BINS: "Bins",
  // EQUIPMENTS: "Equipment",
  // CONCRETE: "Concrete",
}

export const productUnits: any = [
  {text: "Tons", value: "Ton"},
  {text: "Yards", value: "Yard"},
  {text: "Cubic Meters", value: "Cubic Meter"},
  {text: "kilograms", value: "kilogram"},
  {text: "Litres", value: "Litre"},
  {text: "Square meters", value: "Square meter"},
  {text: "Cubic Meters", value: "Cubic Meter"},
  {text: "Rolls", value: "Roll"},
  {text: "Drums", value: "Drum"},
  {text: "Bags", value: "Bag"},
  {text: "Units", value: "Units"},
  {text: "Other", value: "Other"},
]


export const defaultProductImages:any = {
  [productCategoryTypes.AGGREGATE]:aggImg,
  [productCategoryTypes.READY_MIX]:readyMixImg,
  [productCategoryTypes.BINS]:binImg,
  //[productCategoryTypes.CONCRETE]:conImg,
  //[productCategoryTypes.EQUIPMENTS]: skidSteerImg
}

export const activeCategories = () => Object.values(productCategoryTypes)

export const defaultProductImagesTransparent:any = {
  [productCategoryTypes.BINS]:binPng,
  [productCategoryTypes.AGGREGATE]:aggregatePng,
  [productCategoryTypes.READY_MIX]:readyMixPng,

  //[productCategoryTypes.CONCRETE]:concretePng,
  // [productCategoryTypes.EQUIPMENTS]: skidSteerImg
}

export const measurementUnit:any = {
  [productCategoryTypes.BINS]:["Day"],
  [productCategoryTypes.AGGREGATE]:["Yard","Ton"],
  [productCategoryTypes.READY_MIX]: ["Yard"]
  //[productCategoryTypes.EQUIPMENTS]:["Week"],
  //[productCategoryTypes.CONCRETE]:["Yard","Ton"]
}

export const RENTALS: Array<any> = [productCategoryTypes.BINS]

export const orderStatus:any = {
  pending:"PENDING",
  accepted: "ACCEPTED",
  inProgress: "IN PROGRESS",
  delayed: "DELAYED",
  onItsWay: "ON ITS WAY",
  completed: "COMPLETED",
  canceled: "CANCELED",
  rejected: "REJECTED"
}

export const OrderState:any = {
  others: "OPEN",
  completed: orderStatus.completed,
  canceled: orderStatus.canceled,
  rejected: orderStatus.rejected
}

export const tabPages : any = {
  REQUESTS: "REQUESTS",
  ORDERS: "ORDERS",
  RENTALS: "RENTALS"
}

export const PAGE_LIMIT = 10

export const paymentStatusColor:any = {
  paid: {
    color: "#008080",
    bg:"rgba(0,128,128,0.15)"
  },
  pending:{
    color: "#d4af37",
    bg:"rgba(212,175,55,0.15)"
  },
  unpaid:{
    color:"#ff0000",
    bg:"rgba(255,0,0,0.15)"
  }
}

export const PLATFORM_FEE = 25.0

export const ENABLE_PM_CHECK = false;
export const isRental = (category: CATEGORY) => RENTALS.includes(category)

export const maximumImageSize = 20 * 1024 * 1024

export const subAccountPermissions = ["Store", "Orders", "Payment", "SubAccounts", "Products", "Drivers"]
export const contractorSubAccountPermissions =  ["Directory", "Payment", "Orders"]

export const permissions = {
  Store: "store",
  Orders: "orders",
  Payment : "payment",
  SubAccounts: "subaccounts",
  Products: "products",
  Drivers: "drivers",
  Directory: "directory"
}


export const permissionInfo: any = {
  Store: "Allows users to edit and update store information.",
  Orders: "Create and update orders, make payment, accept orders, cancel etc.",
  Payment: "View and update payment information on orders.",
  SubAccounts: "View and Manage sub account.",
  Products: "Add new product to store, edit product info and remove product from store.",
  Drivers: "Add and remove store delivery drivers.",
  Directory: "Create and manage directories for order delivery."
}

export const storeCustomWorkingHoursFormat = {
  Monday: {
    isActive: true,
    hours: [{
      from: "08:00",
      to: "21:00"
    }]
  },
  Tuesday: {
    isActive: false,
    hours: [{
      from: "08:00",
      to: "21:00"
    }]
  },
  Wednesday: {
    isActive: false,
    hours: [{
      from: "08:00",
      to: "21:00"
    }]
  },
  Thursday: {
    isActive: false,
    hours: [{
      from: "08:00",
      to: "21:00"
    }]
  },
  Friday: {
    isActive: false,
    hours: [{
      from: "08:00",
      to: "21:00"
    }]
  },
  Sat: {
    isActive: false,
    hours: [{
      from: "10:00",
      to: "15:00"
    }]
  },
  Sun: {
    isActive: false,
    hours: [{
      from: "12:00",
      to: "18:00"
    }]
  }
}

export const storeBasicWorkingHoursFormat =  {
  "Mon-Fri": {
    isActive: true,
    hours: [{
      from: "08:00",
      to: "21:00"
    }]
  },
  "Sat": {
    isActive: false,
    hours: [{
      from: "10:00",
      to: "15:00"
    }]
  },
  "Sun": {
    isActive: false,
    hours: [{
      from: "12:00",
      to: "18:00"
    }]
  }
}

export const SETUP_STEPS = 4;


 const currencies:any ={
  US: {
    name: "usd",
    symbol: "$"
  }
}

export const currency = currencies.US.symbol


export const SUPPORTED_LANG = [
  {
    key: "en",
    name: "English",
    active: true
  },
  {
    key: "es",
    name: "Spanish",
    active: false
  }
]

export const bidExpiryHours = 1

export const MixpanelEvents = {
   PERSONAL_PRODUCT_CLICK : "Personal Product Click",
  NEW_PRODUCT_CLICK : "New Product Click",
}

export const OrderPageLimit = 10