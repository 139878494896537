import React from 'react'
import styled from "styled-components";

const Wrapper = styled.div<any>`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: ${({gap}) => gap};
`

const Column = React.forwardRef((props:any, ref) => (
  <Wrapper ref={ref} {...props}>{props.children}</Wrapper>
))


export default Column
