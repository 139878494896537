import {createAction} from "@reduxjs/toolkit";
import {
  SET_SELECTED_PRODUCT,
  SET_NEW_PRODUCT_DATA,
  SET_STORE,
  SET_STORE_PRODUCTS,
  SET_STORE_SETUP_DATA, SET_SELECTED_ADDRESS, PAYMENT_DETAILS
} from "./types";


export const supplierAction = (type: string) => createAction(type, (payload: any) => {
  return {
    payload
  }
})

export const setStoreSetUpData = createAction(SET_STORE_SETUP_DATA, (payload:any)=>{
  return {
    payload:payload
  }
})

export const setStore = (payload:any) => supplierAction(SET_STORE)(payload)

export const setNewProductData = (payload :any) => supplierAction(SET_NEW_PRODUCT_DATA)(payload)

export const setStoreProducts = createAction(SET_STORE_PRODUCTS, (payload:any)=>{
  return {
    payload
  }
})

export const setSelectedProduct = createAction(SET_SELECTED_PRODUCT, (payload:any) => {
  return {
    payload
  }
})

export const setSelectedAddress = createAction(SET_SELECTED_ADDRESS, (payload:any)=>{
  return {
    payload
  }
})

export const setSupplierPaymentDetails = createAction(PAYMENT_DETAILS, (payload:any) => {
  return {
    payload
  }
})
