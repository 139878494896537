import {combineReducers} from "@reduxjs/toolkit";
import contractorReducer from "./contractor_reducer";
import supplierReducer from "./supplier_reducer";
import userReducer from "./user_reducer";
import generalReducer from "./general_reducer"

const rootReducer = combineReducers<any>({
  contractor: contractorReducer,
  supplier: supplierReducer,
  user: userReducer,
  general: generalReducer,
})

export {rootReducer}
