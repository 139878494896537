import React from 'react'
import styled from "styled-components";
import {COLORS} from "utils/constants";

const CardWrapper = styled.div<any>`
  cursor: ${(props:any)=> props.disabled ? 'not-allowed' : 'pointer'};
  background: ${(props:any)=> props.disabled ? COLORS.colorBorder : COLORS.colorWhite};
  box-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
  width: auto;
  min-height: fit-content;
  height: fit-content;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  border-color: ${COLORS.colorBorder};

  &:disabled{
    pointer-events: none;
  }
`


const Card = React.forwardRef((props: any, ref) => {
  return <CardWrapper {...props} ref={ref}>
    {props.children}
  </CardWrapper>
})


export default Card
