import axios, {AxiosError, AxiosResponse} from "axios";
import {getIsSubAccount, getPermissions, getToken, getUserType, isApp} from "./local_service";
import toast from "react-hot-toast";


const SERVER_URL = process.env.NODE_ENV ===  "development" ?
  "https://api.buildhubapp.com/api" :
  "https://api.buildhubapp.com/api"

axios.defaults.baseURL = SERVER_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = window.location.origin;
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "*";
axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;



const instance = axios.create({
  baseURL: SERVER_URL,
});



instance.interceptors.request.use(
  (config: any) => {

    config.headers['Authorization'] = `Bearer ${getToken()}`;
    config.headers['userType'] = getUserType();
    config.headers["Permissions"] = getPermissions();
    config.headers["SubAccount"] = getIsSubAccount();
    config.headers["platform"] =  isApp?.() ? "mobile" : "web";

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(function (response: AxiosResponse) {
  if(response.data.code == 302){
    window.location.replace(response.data.data)
  }
  return Promise.resolve(response)
}, function (error: AxiosError) {
  if(error.response?.status === 401){
    const finalPath = `${window.location.pathname}`
    window.location.replace(`${window.origin}/auth/login/as?r=${finalPath}`)
  }
  const data: any = error.response?.data
  if (data?.message) {
    toast.error(data?.message)
  }
  return Promise.reject(error)
})


export { instance }






