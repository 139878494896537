export const SET_PERSONAL_INFO="SET_PERSONAL_INFO"
export const SET_PAYMENT_INFO="SET_PAYMENT_INFO"
export const SET_PASSWORD_INFO="SET_PASSWORD_INFO"
export const SET_USER_ID="SET_USER_ID"
export const SET_DELIVERY_ADDRESS="SET_DELIVERY_ADDRESS"
export const SET_SELECTED_DELIVERY_ADDRESS="SET_SELECTED_DELIVERY_ADDRESS"
export const ADD_NEW_ADDRESS="ADD_NEW_ADDRESS"
export const SET_STORE_SETUP_DATA="SET_STORE_SETUP_DATA"
export const SET_STORE="SET_STORE"
export const SET_NEW_PRODUCT_DATA="SET_NEW_PRODUCT_DATA"
export const SET_STORE_PRODUCTS="SET_STORE_PRODUCTS"
export const SET_SELECTED_PRODUCT="SET_SELECTED_PRODUCT"
export const SET_SELECTED_ADDRESS="SET_SELECTED_ADDRESS"
export const SAVE_CART="SAVE_CART"
export const PAYMENT_DETAILS="PAYMENT_DETAILS"
export const SET_CONTRACTOR_ORDERS="SET_CONTRACTOR_ORDERS"
export const SET_SUPPLIER_ORDERS="SET_SUPPLIER_ORDERS"
export const SET_ADDRESS_STORES="SET_ADDRESS_STORES"
export const SET_SELECTED_ORDER="SET_SELECTED_ORDER"
export const SET_BUSINESS_INFO="SET_BUSINESS_INFO"
export const SET_STORE_RATING="SET_STORE_RATING"
export const SET_SUPPLIER_SELECTED_ORDER="SET_SUPPLIER_SELECTED_ORDER"
export const SET_STORE_DRIVERS="SET_STORE_DRIVERS"
export const SET_SELECTED_DRIVER="SET_SELECTED_DRIVER"
export const SET_CONTRACTOR_RENTALS="SET_CONTRACTOR_RENTALS"
export const SET_SUPPLIER_RENTALS="SET_SUPPLIER_RENTALS"
export const USER_NOTIFICATIONS="USER_NOTIFICATIONS"
export const SELECTED_ADMIN="SELECTED_ADMIN"
export const CONTRACTOR_SUBACCOUNTS="CONTRACTOR_SUBACCOUNTS"
export const ORDER_REQUEST_DATA = "ORDER_REQUEST_DATA"
export const NEW_ORDER_MODAL = "NEW_ORDER_MODAL"

export const MAIN_ORDER_TAB= "MAIN_ORDER_TAB"
export const ORDER_FILTER_TAB = "ORDER_FILTER_TAB"

export const SET_CONTRACTOR_ORDER_REQUEST = "SET_CONTRACTOR_ORDER_REQUEST"

export const SET_LOCATION_STORES="SET_LOCATION_STORES"
export const SET_FAVORITE_STORES="SET_FAVORITE_STORES"

export const SET_SUPPLIER_REQUESTS = "SET_SUPPLIER_REQUESTS"

export const SET_CONTRACTOR_REQUESTS = "SET_CONTRACTOR_REQUESTS"
export const SET_CONTRACTOR_PAYMENT_METHODS = "SET_CONTRACTOR_PAYMENT_METHODS"

export const NEW_NOTIFICATION_MESSAGE = "NEW_NOTIFICATION_MESSAGE";

export const UPDATE_ORDER_COMPLETED = "UPDATE_ORDER_COMPLETED";

export const TOGGLE_SNACK_BAR = "TOGGLE_SNACK_BAR";

export const CONTRACTOR_RECENT_PROJECTS = "CONTRACTOR_RECENT_PROJECTS";

export const CONTRACTOR_RECENT_ACTIVITIES = "CONTRACTOR_RECENT_ACTIVITIES";
export const CONTRACTOR_STATS = "CONTRACTOR_STATS"

export const CONTRACTOR_DELIVERY_TODAY = "CONTRACTOR_DELIVERY_TODAY";
export const CONTRACTOR_RENTAL_EXPIRY = "CONTRACTOR_RENTAL_EXPIRY"

export const SUPPLIER_DELIVERY_THIS_WEEK = "SUPPLIER_DELIVERY_THIS_WEEK"
export const SUPPLIER_RENTAL_READY_PICKUP = "SUPPLIER_RENTAL_READY_PICKUP"

export const ZOOM_IMAGE = "ZOOM_IMAGE"
