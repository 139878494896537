import {COLORS} from "../../utils/constants";
import React from "react";
import {FadeLoader} from "react-spinners";
import {Column} from "../../layouts";

type loaderSize = "small" | "medium" |  "large"
const Loader = ({color, size}:{
  color?: string,
  size?: loaderSize
}) => {
 return (
   <Column style={{width:'100%', alignItems:'center', justifyContent:'center', padding:'12px 0'}}>
     <div style={{
       width: 'fit-content',
       display: 'flex',
       justifyContent: 'center',
       height: 'fit-content',
       transform: size === "small" ? 'scale(0.4)' : size === "medium" ? 'scale(0.5)' : 'scale(0.6)',
     }}>
       <FadeLoader style={{
         marginBottom:'8px'
       }} height={12} radius={2} color={color || COLORS.colorBlack} loading={true}/>
     </div>
   </Column>
  )
}


export default Loader
