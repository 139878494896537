import styled from "styled-components";
import Card from "components/Card";
import {COLORS, MEDIA} from "utils/constants";
import React from "react";
import Button from "components/Button";
import {contractorAction} from "redux/action/contractor_actions";
import {TOGGLE_SNACK_BAR} from "redux/action/types";
import {AnimatePresence, motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {isApp} from "services/local_service";
import {Cancel} from "components/common/Icons";


const Wrapper = styled(Card)`
  background-color: black;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: min(100%, 450px);
  display: flex;
  padding: 0.6rem;
  align-items: center;
  justify-content: space-between;
  bottom: 1rem;
  z-index: 1000;
  flex-direction: row!important;
  
  p{
    font-size: 14px;
    color: #F9E600;
    margin:0 6px;
    font-weight: 500;
  }

  ${MEDIA.isMobile} {
    width: calc(100% - 2.8rem);
    bottom: ${isApp() ? 'calc(64px + 1rem)' : 'calc(54px + 1rem)'};
    margin: auto;
    left: 0;
    right: 0;
    top: unset;
    z-index: 1000;
    p{
      font-size: 13px;
    }
  }
`

const SnackBarMessage = () => {

  const {snackBar} = useSelector((state: any) => state.general);
  const dispatch = useDispatch();

  return (
    <AnimatePresence>
      {
        snackBar?.open && (
          <motion.div
            initial={{
              opacity: 0,
            }}
          animate={{
            opacity:1,
          }}
          exit={{
            opacity : 0,
          }}
            transition={{
              type:'spring',
              scale: {duration: 1}
            }}
          >
            <Wrapper>
              <p>{snackBar?.message}</p>
              <button className={"flat-button"} onClick={() => {
                dispatch(contractorAction(TOGGLE_SNACK_BAR)({open: false, message: null}))
              }} style={{backgroundColor: COLORS.primaryYellow,
                height:'24px',
                minHeight:'24px',
                maxHeight:'24px',
                width:'24px',
                minWidth:'24px',
                maxWidth:'24px',
                borderRadius:'100%'}} ><Cancel size={16} color={COLORS.colorBlack}/></button>
            </Wrapper>
          </motion.div>
        )
      }

    </AnimatePresence>
  )
}

export default SnackBarMessage
