import React, {useEffect} from 'react'
// @ts-ignore
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
// @ts-ignore
import Lightbox from "react-awesome-lightbox";
import {lazy} from 'hooks/use-enforced-lazy'
// @ts-ignore
import * as Sentry from '@sentry/react'

import PageLoading from "../layouts/PageLoading";
import {getFirebaseConfig} from "utils/remoteParams";
import {fetchAndActivate} from "firebase/remote-config";
import {firebaseRemoteConfig} from "services/firebase_service";




import SnackBarMessage from "components/SnackBar"
import {useDispatch, useSelector} from "react-redux";
import {contractorAction} from "redux/action/contractor_actions";
import {NEW_NOTIFICATION_MESSAGE, ZOOM_IMAGE} from "redux/action/types";
import {supplierAction} from "redux/action/supplier_actions";
import logoRounded from 'assets/images/logo_rounded.png'
import {isApp} from "services/local_service";
import useWebsocket from "hooks/use-websocket";


const SplashScreen = lazy(()=> import("./SplashScreen"))

const ContractorNavigation =  lazy(()=> import("./Contractor"))
const SupplierNavigation = lazy(()=> import("./Supplier"))


const StoreSetup = lazy(() => import("./Supplier/SetUpStore"));
const Terms = lazy(()=> import("./Terms"));
const DriverDashboard = lazy(()=> import("./Driver/Dashboard"));
const RequestPaymentCallback = lazy(()=> import("./Payment/RequestPaymentCallback"));

const AuthRoute = lazy(()=> import("./Authentication"));
const Route404 = lazy(()=> import("../layouts/404"));

const InviteLandingPage = lazy(()=> import("./InviteLandingPage"))


//const pusherInstanceId = "007d8b6f-5593-43d4-af47-47b9b0f76732"

const AppRouter = () => {

  const {zoomImage} = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const {userId} = useSelector((state: any) => state.user ?? {});
  const navigate = useNavigate();

  /**
   useLayoutEffect(() => {
    if (!isApp() && isMobile) {
      try {
        const hostname = window.location.origin
        const now = new Date().valueOf();
        setTimeout(function () {
          if (new Date().valueOf() - now > 100) return;
          window.location.href = `${hostname}/${window.location.pathname}`;
        }, 25);
        window.location.href = `buildhub://${window.location.pathname}`
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  }, [isApp]);
  * */

  useEffect(() => {
    if (userId) {
      try{
        const topic = `/topic/notification_${userId}`
        useWebsocket(topic, (data:any) => {
          dispatch(supplierAction(NEW_NOTIFICATION_MESSAGE)(data));
          if (!isApp()) {
            const permission = Notification?.permission;
            if (permission === "granted") {
              const {page, message} = data;
              const notification = new Notification("BuildHub", {
                body: message,
                icon: logoRounded
              });
              notification?.addEventListener('click', () => {
                navigate(page)
              })
            }
          }
        });
      }catch (e) {
        Sentry.captureException(e)
      }
    }
  }, [userId, isApp]);

  useEffect(()=> {
    fetchAndActivate(firebaseRemoteConfig()).then(async ()=>{
      await getFirebaseConfig();
    }).catch(console.log);
  }, [])


  return (
          <>
            <Routes>
              <Route path={"/"} element={<React.Suspense fallback={<PageLoading/>}>
                <SplashScreen/>
              </React.Suspense>}/>

              <Route path={"/signup"} element={<Navigate to={"/auth/signup/as"}/>}/>

              <Route path={"/auth/*"} element={<React.Suspense fallback={<PageLoading/>}><AuthRoute/></React.Suspense>}/>

              <Route path={"/contractor"}
                     element={<Navigate to={"/contractor/dashboard/"}/>}
              />
              <Route path={"/supplier"} element={<Navigate to={"/supplier/dashboard/"}/>}/>

              <Route path={"/contractor/dashboard/*"} element={<React.Suspense fallback={<PageLoading/>}><ContractorNavigation/></React.Suspense>}/>

              <Route path={"/supplier/dashboard/*"} element={<React.Suspense fallback={<PageLoading/>}><SupplierNavigation/></React.Suspense>}/>

              <Route path={"/driver/dashboard"}
                     element={<React.Suspense fallback={<PageLoading/>}><DriverDashboard/></React.Suspense>}/>

              <Route path={"/supplier/store/setup"}
                     element={<React.Suspense fallback={<PageLoading/>}><StoreSetup/></React.Suspense>}/>

              <Route path={"/payment/:id/details"} element={<React.Suspense fallback={<PageLoading/>}>
                <RequestPaymentCallback/>
              </React.Suspense>}/>

              <Route path={"/terms-of-service"}
                     element={<React.Suspense fallback={<PageLoading/>}>
                       <Terms/>
                     </React.Suspense>}
              />

              <Route path={"/invite"}
                     element={<React.Suspense fallback={<PageLoading/>}>
                       <InviteLandingPage/>
                     </React.Suspense>}
              />

              <Route path={"/undefined/*"}
                     element={<Navigate to={"/auth/login/as"}/>}
              />

              <Route path={"/null/*"}
                     element={<Navigate to={"/auth/login/as"}/>}
              />

              <Route path={"*"} element={<React.Suspense fallback={<PageLoading/>}><Route404/></React.Suspense>}/>
            </Routes>

            <SnackBarMessage/>
            {
              !!zoomImage && <Lightbox alt={"completed_image"} image={zoomImage} onClose={()=> {
                dispatch(contractorAction(ZOOM_IMAGE)(null))
              }}/>
            }
          </>
  )
}

export default AppRouter
