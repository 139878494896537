import styled from "styled-components";
import React from "react";

const Wrapper = styled.div<any>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: ${({gap}) => gap};
`;


const Row = React.forwardRef((props: any, ref) => (
  <Wrapper ref={ref} {...props}>
    {props.children}
  </Wrapper>
))

export default Row
