import {ORDER_FILTER_TAB, TOGGLE_SNACK_BAR} from "redux/action/types";
import {createReducer} from "@reduxjs/toolkit";
import {subDays} from "date-fns";

const INITIAL_STATE = {
  orderFilter: {
    orderState: "OPEN",
    startDate: subDays(new Date(), 7),
    endDate: new Date()
  },
  snackBar:{
    open: false,
    message: ""
  }
}

const generalReducer = createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(ORDER_FILTER_TAB, (state: any, action: any) => {
      return {
        ...state,
        orderFilter: action.payload
      }
    })
    .addCase(TOGGLE_SNACK_BAR, (state:any, action:any)=> {
      return {
        ...state,
        snackBar: action.payload
      }

      }
    )

    .addDefaultCase((state) => {
      return state
    })
})

export default generalReducer

