import moment from 'moment'
import {Location} from "../@types/Types";
import {formatDateShort, isApp} from "services/local_service";
import {isBefore, isToday} from "date-fns";
import {activeCategories, AUTH_HOST, orderStatus, userTypes} from "utils/constants";


const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const isValidEmail = (email: string) => {
  return String(email).match(emailRegex)
}


export const getRentalExpiryDate = (date:string, duration: number) => {
  const expiry = moment(date).add(duration, "day").toString();
  return formatDateShort(expiry);
}


export  const isTimeAfterHour = (time:string, hours: number) => {
  const afterHours = moment().add(hours, "hours");
  return moment(time, "HH:mm").isAfter(afterHours)
}


export const getMainUserId = (userInfo:any) =>  userInfo?.isSubAccount ? userInfo?.mainContractorId : userInfo?.id


export const calculateDistance = (storeLocation:Location, deliveryAddress: Location): Promise<number> => {
  const directionsApi = new google.maps.DirectionsService();
  const route: any = {
    origin: storeLocation,
    destination:deliveryAddress,
    travelMode:'DRIVING'
  }

  return new Promise((resolve, reject) => {
    directionsApi.route(route, (response)=> {
      const distance = response?.routes[0].legs[0].distance
      resolve((distance?.value || 1) / 1609)
    }).catch(()=> {
      reject(null)
    })
  })
}


export const socketHost = "wss://api.buildhubapp.com/buildhub-ws" ;

// ""

export const randomColor = (): string => "#"+Math.floor(Math.random()*16777215).toString(16);

export const displayGreeting =()=>{

  const myDate = new Date();
  const hrs = myDate.getHours();
  let greet;

  if (hrs < 12)
    greet = 'Good Morning';
  else if (hrs >= 12 && hrs <= 17)
    greet = 'Good Afternoon';
  else if (hrs >= 17 && hrs <= 24)
    greet = 'Good Evening';
  return  greet
}

export const injectCSS = (css:any) => {
  const el = document.createElement('style');
  el.type = 'text/css';
  el.innerText = css;
  document.head.appendChild(el);
  return el;
};

export const isDeliveryDay = (deliveryDateInfo: string) =>
  (isToday(new Date(deliveryDateInfo)) ||
    isBefore(new Date(deliveryDateInfo), new Date()));


export const isOrderActive = (status: string) => ![orderStatus.canceled, orderStatus.completed, orderStatus.rejected].includes(status)


export const filenameFromLink = (link: string) =>  link.substring(link.lastIndexOf('/')+1);

export const initSsoAuth = (provider?:string,  userType?: string, action?: string, inviteCode?: any) => {
  const redirectUrl = isApp() ? `buildhub:/${window.location.pathname}` : `${window.location.href?.split("?")[0]}`
  return `${AUTH_HOST}?provider=${provider}&redirect=${redirectUrl}&source=${isApp() ? "mobile" : "web"}&userType=${userType}&action=${action}&inviteCode=${inviteCode}`
}

export const formatPriceAmount = (amount:any) => parseFloat(parseFloat(String(amount)).toFixed(1)).toLocaleString()


export const generateTime = () => {
  const times = [];
  let hours = 0;
  let minutes = 0;

  for (let i = 0; i < 48; i++) {
    const hourStr = (hours < 10 ? '0' : '') + hours.toString();
    const minuteStr = (minutes < 10 ? '0' : '') + minutes.toString();
    times.push(hourStr + ':' + minuteStr);

    // Increment time by 30 minutes
    minutes += 30;
    if (minutes >= 60) {
      hours++;
      minutes = 0;
    }
  }

  return times;
}

export function formatMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if(hours > 0) return `${hours}hr ${remainingMinutes}mins`
  return `${remainingMinutes}mins`;
}


export const isSupplier = (type: string) => [userTypes.SUPPLIER].some(t => String(t).toUpperCase() === type.toUpperCase());
export const isNormalUser = (type: string) => [userTypes.CONTRACTOR, userTypes.HOMEOWNER].some(t => String(t).toUpperCase() === type.toUpperCase());
export const isDriver = (type: string) => [userTypes.DRIVER].some(t => String(t).toUpperCase() === type.toUpperCase());
export const filterActiveCategories = (category:string) => activeCategories().includes(category)